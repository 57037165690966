














































































































import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    useContext,
    useRouter
} from '@nuxtjs/composition-api'

import { fetchUser, user, userFullName } from '@/composables/user'

import Logo from '@/assets/img/logo-pro-gray.svg'

import { openedAside, openAside, closeAside } from '@/composables/asides'
import { checkLoginAsClaim, removeLoginAsClaim } from '@/composables/login-as-claim'
import { useDeviceWatcher } from "@/composables/device-watcher"
import { getCabinetTypes, getSelectedCabinet } from "@/composables/cabinet-type"

import HeaderBurger from './-HeaderBurger.vue'
import HeaderSearchButton from './-HeaderSearchButton.vue'
import HeaderProfile from './-HeaderProfile.vue'
import HeaderNotificationsButton from './-HeaderNotificationsButton.vue'
import HeaderMessagesButton from './-HeaderMessagesButton.vue'
import HeaderNavigation from './-HeaderNavigation.vue'
import HeaderSearchBar from './-HeaderSearchBar.vue'
import HeaderCabinetType from './-HeaderCabinetType.vue'

export default defineComponent({
    components: {
        Logo,
        HeaderBurger,
        HeaderSearchButton,
        HeaderProfile,
        HeaderNotificationsButton,
        HeaderMessagesButton,
        HeaderNavigation,
        HeaderSearchBar,
        HeaderCabinetType
    },
    props: {
        ...HeaderNavigation.props,
        catalogIsOpen: {
            type: Boolean,
            default: false
        },
        searchIsOpen: {
            type: Boolean,
            default: false
        },
        profileIsOpen: {
            type: Boolean,
            default: false
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        hideCatalogAside: {
            type: Boolean,
            default: false
        },
        unreadNotificationsCount: {
            type: Number,
            default: 0
        },
        hideNotifications: {
            type: Boolean,
            default: false
        }
    },
    setup: () => {
        const { route } = useContext()
        const searchQuery = ref('')
        const channelId = ref()
        const unreadMessagesCount = ref()
        const context = useContext()
        const router = useRouter()
        const { $apiCustomer, $config } = context
        const {
            attach,
            detach,
            isDesktop,
            isTable,
            currentDevice
        } = useDeviceWatcher([
            { alias: 'mobile', breakpoint: 0 },
            { alias: 'table', breakpoint: 650 },
            { alias: 'desktop', breakpoint: 1024 }
        ])

        const logout = async () => {
            removeLoginAsClaim()

            window.location.href = `${$config.AUTH_ENDPOINT}/logout-as-claim` as string
        }

        const isShowAsClaimButton = ref(false)

        const getMessagesCounter = async () => {
            const { data: count } = await $apiCustomer.worklegramMessagesGetUnreadMessagesInfo(
                null,
                {
                    userId: user.value.id,
                    channelId: channelId.value,
                    status: 'Unread'
                }
            )
            unreadMessagesCount.value = count.unreadMessagesCount
        }

        const getChannelId = async () => {
            const { data } = await $apiCustomer.worklegramChannelsGetChannelByType(null, {
                userId: user.value.id,
                channelTypeAlias: 'LoyaltyProgram'
            })
            channelId.value = data.clickHouseChannel?.channelId

            if (channelId.value) {
                await getMessagesCounter()
            }
        }

        const onSearch = (searchQueryString: string) => {
            closeAside()

            router.push({
                path: '/showcases/categories',
                query: {
                    ...route.value?.query,
                    search: searchQueryString
                }
            })
        }

        onMounted(async () => {
            attach()
            isShowAsClaimButton.value = checkLoginAsClaim()
            try {
                await fetchUser(context)
                await getCabinetTypes(context)

                if (currentDevice.value?.alias === 'desktop') {
                    await getChannelId()
                }

                getSelectedCabinet()
            } catch (error) {
                console.log(error)
            }
        })

        onUnmounted(() => {
            detach()
        })

        return {
            channelId,
            unreadMessagesCount,
            isShowAsClaimButton,
            isTable,
            openedAside,
            openAside,
            closeAside,
            user,
            userFullName,
            searchQuery,
            isDesktop,
            onSearch,
            logout
        }
    }
})
