


























import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

import MagnifierIcon from '@/assets/img/magnifier.svg'

import AppInput from './index.vue'
import AppInputButton from './-AppInputButton.vue'

export default defineComponent({
    components: {
        AppInputButton,
        MagnifierIcon
    },
    props: {
        ...AppInput.props,
        isSearchIconShow: {
            type: Boolean,
            default: true
        }
    },
    setup: (props, { emit }) => {
        const $input = ref<InstanceType<typeof AppInput> | null>(null)
        const focused = computed(() => $input.value?.focused)

        const onSearch = () => {
            emit('on-search', props.value)
        }

        return {
            onSearch,
            $input,
            focused
        }
    }
})
