import { render, staticRenderFns } from "./AppInputSearch.vue?vue&type=template&id=28edd73a&scoped=true&"
import script from "./AppInputSearch.vue?vue&type=script&lang=ts&"
export * from "./AppInputSearch.vue?vue&type=script&lang=ts&"
import style0 from "./AppInputSearch.vue?vue&type=style&index=0&id=28edd73a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28edd73a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppInput: require('/var/www/app/src/components/ui/AppInput/index.vue').default})
