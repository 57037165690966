var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-input', _vm._g(_vm._b({
    ref: "$input",
    staticClass: "app-input-search",
    on: {
      "on-clear": function ($event) {
        return _vm.$emit('on-clear');
      }
    },
    nativeOn: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.onSearch.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([_vm.isSearchIconShow ? {
      key: "buttons-append",
      fn: function () {
        return [_c('transition', {
          attrs: {
            "name": "fade"
          }
        }, [_c('app-input-button', {
          staticClass: "app-input-search__button",
          attrs: {
            "disabled": _vm.disabled
          },
          on: {
            "on-click": _vm.onSearch
          }
        }, [_c('magnifier-icon', {
          staticClass: "app-input-search__icon"
        })], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, 'app-input', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }